import React, { useEffect, useState } from "react";
import {
  getSecureCodeByChildIdAPI,
  getUsersByTypeAPI,
} from "../service/AuthService";
import ReactSelect from "react-select";
export default function SecureCodeReport() {
  const [users, setUsers] = useState([]);
  const [userType, setUserType] = useState(null);
  const [selectedUserName, setSelectedUserName] = useState("");
  const [otp, setOtp] = useState("");
  const [payload, setPayload] = useState({
    dataReportType: "SECURE_CODE",
    userType: "",
    toFetchFor: "",
  });
  const getUsersByType = async () => {
    const { response, code } = await getUsersByTypeAPI(
      userType?.toLocaleUpperCase()
    );
    if (code === 200) {
      setUsers(response?.users);
    }
  };
  const getSecureCodeReport = async () => {
    const { response, code } = await getSecureCodeByChildIdAPI(
      payload?.toFetchFor
    );
    if (code === 200) {
      setOtp(response?.otp);
    }
  };
  useEffect(() => {
    if (userType) {
      getUsersByType();
    }
  }, [userType]);
  return (
    <div
      className="gx-main-content-wrapper"
      style={{ marginTop: 50, marginBottom: 100 }}
    >
      <div className="ant-row ant-row-center">
        <div className="ant-col ant-col-xs-24 ant-col-lg-24">
          <div className="ant-row ant-row-center">
            <div className="ant-card ant-card-bordered gx-card gx-w-100 gx-mb-0">
              <div className="ant-card-body">
                <div className="gx-bg-grey gx-px-5 gx-pt-3 gx-bg-flex">
                  <span className="gx-fs-xl gx-font-weight-normal gx-text-white gx-align-items-center gx-pt-1 gx-text-capitalize">
                    Secure Code
                  </span>
                  <button type="button" className="ant-btn ant-btn-primary">
                    <span>Back</span>
                  </button>
                </div>
                <div
                  style={{
                    padding: 10,
                  }}
                >
                  <div
                    _ngcontent-ng-c219872124=""
                    className="col-6 col-md-4 col-lg-3"
                  >
                    <div
                      _ngcontent-ng-c219872124=""
                      className="input_block position-relative"
                    >
                      <label _ngcontent-ng-c219872124="">User Type</label>
                      <ReactSelect
                        options={[
                          {
                            label: "Client",
                            value: "CLIENT",
                          },
                          {
                            label: "Master",
                            value: "MASTER",
                          },
                          {
                            label: "Admin",
                            value: "ADMIN",
                          },
                          {
                            label: "Sub Admin",
                            value: "SUB_ADMIN",
                          },
                          {
                            label: "Agent",
                            value: "AGENT",
                          },
                        ]}
                        onChange={(e) => {
                          setUserType(e.value);
                          setPayload({
                            ...payload,
                            userType: e.value,
                          });
                        }}
                      />
                      {/**/}
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c219872124=""
                    className="col-6 col-md-4 col-lg-3"
                  >
                    <div
                      _ngcontent-ng-c219872124=""
                      className="input_block position-relative"
                    >
                      <label _ngcontent-ng-c219872124="">User</label>
                      <ReactSelect
                        options={users.map((user) => ({
                          label: user.userName + " " + user?.name,
                          value: user.userId,
                        }))}
                        onChange={(e) => {
                          setSelectedUserName(e.value);
                          setSelectedUserName(e.label?.split(" ")[0]);
                          setPayload({
                            ...payload,
                            toFetchFor: e.value,
                          });
                        }}
                      />
                      {/**/}
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={getSecureCodeReport}
                    className="ant-btn ant-btn-primary gx-mx-4 gx-mt-2 gx-mb-2 gx-border-redius0"
                    ant-click-animating-without-extra-node="false"
                  >
                    <span>Show</span>
                  </button>
                </div>
                <div className="ant-table-wrapper gx-table-responsive gx-w-100 login-table-header">
                  <div className="ant-spin-nested-loading">
                    <div className="ant-spin-container">
                      <div className="ant-table ant-table-small ant-table-bordered">
                        <div className="ant-table-container">
                          <div className="ant-table-content">
                            <table style={{ tableLayout: "auto" }}>
                              <colgroup />
                              <thead className="ant-table-thead">
                                <tr>
                                  <th className="ant-table-cell">Code</th>
                                  <th className="ant-table-cell">OTP</th>
                                  <th className="ant-table-cell">CREATED ON</th>
                                </tr>
                              </thead>
                              <tbody className="ant-table-tbody">
                                <tr
                                  data-row-key={1}
                                  className="ant-table-row ant-table-row-level-0"
                                >
                                  <td className="ant-table-cell">
                                    {selectedUserName}
                                  </td>
                                  <td className="ant-table-cell">{otp}</td>
                                  <td className="ant-table-cell">
                                    11 Nov 12:40:22 PM
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
