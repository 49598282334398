import React, { useState } from "react";

export default function DWModal({
  clientData,
  isModalOpen,
  setIsModalOpen,
  handleSubmit,
}) {
  const [amount, setAmount] = useState(0);
  return (
    <div
      tabIndex={-1}
      className="ant-modal-wrap "
      role="dialog"
      aria-labelledby="rcDialogTitle0"
    >
      <div
        role="document"
        className="ant-modal"
        style={{ width: 520, transformOrigin: "-54.5px 260px" }}
      >
        <div
          tabIndex={0}
          aria-hidden="true"
          style={{ width: 0, height: 0, overflow: "hidden" }}
        />
        <div className="ant-modal-content">
          <button
            onClick={() => setIsModalOpen(false)}
            type="button"
            aria-label="Close"
            className="ant-modal-close"
          >
            <span className="ant-modal-close-x">
              <i
                aria-label="icon: close"
                className="anticon anticon-close ant-modal-close-icon"
              >
                <svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  className=""
                  data-icon="close"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z" />
                </svg>
              </i>
            </span>
          </button>
          <div className="ant-modal-header">
            <div className="ant-modal-title" id="rcDialogTitle0">
              <span>
                <h1
                  style={{
                    display: "inline-block",
                    fontSize: 28,
                    color: "rgb(255, 255, 255)",
                  }}
                >
                  {clientData?.username}
                </h1>{" "}
                {clientData?.isDeposit ? "Deposit" : "Withdraw"} Chips
              </span>
            </div>
          </div>
          <div className="ant-modal-body">
            <h1>Curr Coins : {clientData?.balance}</h1>
            <p>
              <input
                type="number"
                placeholder="chips"
                className="ant-input"
                defaultValue=""
                value={amount}
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
              />
            </p>
          </div>
          <div className="ant-modal-footer">
            <button
              onClick={() => {
                setIsModalOpen(false);
              }}
              type="button"
              className="ant-btn"
            >
              <span>Return</span>
            </button>
            <button
              onClick={() => handleSubmit(amount, clientData)}
              type="button"
              className="ant-btn ant-btn-primary"
            >
              <span>Submit</span>
            </button>
          </div>
        </div>
        <div
          tabIndex={0}
          aria-hidden="true"
          style={{ width: 0, height: 0, overflow: "hidden" }}
        />
      </div>
    </div>
  );
}
