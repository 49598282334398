import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getClientAPI } from "../service/UserService";
import { updateCommissionAPI } from "../service/AuthService";
import toast from "react-hot-toast";

export default function ClientLimitUpdate() {
  const userData = useSelector((state) => state.account.userData);
  const params = useParams();
  const [search, setSearch] = useState("");
  const [clientData, setClientData] = useState({});

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(10);
  const location = useLocation();
  const [userType, setUserType] = useState(params?.userType?.toUpperCase());
  const [id, setId] = useState(location?.state?.userName || "");
  const [updateCommission, setUpdateCommission] = useState({});

  const getClient = async () => {
    const { response } = await getClientAPI(
      search,
      currentPage,
      size,
      id,
      params?.userType.toUpperCase()
    );
    let response_ = response;
    if (response_) {
      response_.childrenUserResponseDTOS = response_.childrenUserResponseDTOSPage.content.sort(
        (a, b) => a.uid - b.uid
      );
      setCurrentPage(response_.childrenUserResponseDTOSPage.number);
      setTotalPages(response_.childrenUserResponseDTOSPage.totalPages);
      setTotalElements(response_.childrenUserResponseDTOSPage.totalElements);
      setSize(response_.childrenUserResponseDTOSPage.size);
    }
    setClientData(response_);
  };
  const handleUpdateCommission = async (type) => {
    let updatedLimit;
    if (type === "Minus") {
      updatedLimit = -updateCommission.currentLimit;
    } else {
      updatedLimit = updateCommission.currentLimit;
    }
    try {
      const { response, code } = await updateCommissionAPI({
        ...updateCommission,
        currentLimit: updatedLimit,
      });
      if (code === 200) {
        toast.success("Limit Updated Successfully");
        getClient();
        window.location.reload();
        setUpdateCommission({
          ...updateCommission,
          currentLimit: "",
        });
      } else {
        toast.error(response);
      }
    } catch (e) {
      toast.error("Something went wrong");
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    getClient();
  }, [location.state, currentPage]);
  return (
    <main className="ant-layout-content gx-layout-content  ">
      <div className="gx-main-content-wrapper" style={{ marginBottom: 100 }}>
        <div className="ant-row ant-row-center">
          <div className="ant-col ant-col-xs-24 ant-col-lg-24">
            <div className="ant-row">
              <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-12 ant-col-lg-24">
                <div className="ant-card ant-card-bordered gx-card">
                  <div className="ant-card-body">
                    <div className="gx-bg-grey gx-px-5 gx-pt-2 gx-bg-flex gx-align-items-center">
                      <span className="gx-fs-2xl gx-font-weight-normal gx-text-white gx-align-items-center gx-text-capitalize">
                        Update Limit
                      </span>
                      <button
                        type="button"
                        className="ant-btn ant-btn-primary"
                      >
                        <span>Back</span>
                      </button>
                    </div>
                    <div className="ant-table-wrapper gx-table-responsive">
                      <div className="ant-spin-nested-loading">
                        <div className="ant-spin-container">
                          <div className="ant-table ant-table-small ant-table-bordered">
                            <div className="ant-table-container">
                              <div className="ant-table-content">
                                <table style={{ tableLayout: "auto" }}>
                                  <colgroup />
                                  <thead className="ant-table-thead">
                                    <tr>
                                      <th className="ant-table-cell">
                                        <div className="ant-table-filter-column">
                                          <span className="ant-table-column-title">
                                            Code
                                          </span>
                                          <span
                                            role="button"
                                            tabIndex={-1}
                                            className="ant-dropdown-trigger ant-table-filter-trigger"
                                          >
                                            <span
                                              role="img"
                                              aria-label="search"
                                              className="anticon anticon-search"
                                            >
                                              <svg
                                                viewBox="64 64 896 896"
                                                focusable="false"
                                                data-icon="search"
                                                width="1em"
                                                height="1em"
                                                fill="currentColor"
                                                aria-hidden="true"
                                              >
                                                <path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z" />
                                              </svg>
                                            </span>
                                          </span>
                                        </div>
                                      </th>
                                      <th className="ant-table-cell">
                                        <div className="ant-table-filter-column">
                                          <span className="ant-table-column-title">
                                            Name
                                          </span>
                                          <span
                                            role="button"
                                            tabIndex={-1}
                                            className="ant-dropdown-trigger ant-table-filter-trigger"
                                          >
                                            <span
                                              role="img"
                                              aria-label="search"
                                              className="anticon anticon-search"
                                            >
                                              <svg
                                                viewBox="64 64 896 896"
                                                focusable="false"
                                                data-icon="search"
                                                width="1em"
                                                height="1em"
                                                fill="currentColor"
                                                aria-hidden="true"
                                              >
                                                <path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z" />
                                              </svg>
                                            </span>
                                          </span>
                                        </div>
                                      </th>
                                      <th className="ant-table-cell">
                                        C.Chip
                                      </th>
                                      <th className="ant-table-cell">
                                        Add / Minus Limit
                                      </th>
                                      <th className="ant-table-cell">
                                        Action
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="ant-table-tbody">
                                    {clientData?.childrenUserResponseDTOS?.map(
                                      (client, index) => (
                                        <tr
                                          data-row-key="6731b25054f4f6f226cdece0"
                                          className="ant-table-row ant-table-row-level-0"
                                        >
                                          <td className="ant-table-cell">
                                            {client?.username}
                                          </td>
                                          <td className="ant-table-cell">
                                            {client?.name}
                                          </td>
                                          <td className="ant-table-cell">
                                            {parseFloat(
                                              client?.balance +
                                                client?.liability
                                            ).toFixed(2)}
                                          </td>
                                          <td className="ant-table-cell">
                                            <div
                                              className="ant-space ant-space-vertical"
                                              style={{ gap: 8 }}
                                            >
                                              <div className="ant-space-item">
                                                <input
                                                  className="ant-input  gx-border-redius0"
                                                  type="text"
                                                  onChange={(e) => {
                                                    setUpdateCommission({
                                                      ...updateCommission,
                                                      id: client?.uid,
                                                      currentLimit:
                                                        e.target.value,
                                                    });
                                                  }}
                                                  defaultValue=""
                                                  style={{ width: 100 }}
                                                />
                                              </div>
                                            </div>
                                          </td>
                                          <td className="ant-table-cell">
                                            <div className="gx-bg-flex gx-justify-content-start">
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  handleUpdateCommission(
                                                    "Plus"
                                                  )
                                                }
                                                className="ant-btn ant-btn-default gx-bg-primary gx-text-white gx-border-redius0"
                                              >
                                                <span>Add</span>
                                              </button>
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  handleUpdateCommission(
                                                    "Minus"
                                                  )
                                                }
                                                className="ant-btn ant-btn-default gx-bg-grey gx-border-red gx-text-white gx-border-redius0"
                                              >
                                                <span>Minus</span>
                                              </button>
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="gx-text-right gx-my-2 gx-mx-3 ">
                      <ul className="ant-pagination ant-pagination-mini">
                        <li
                          title="Previous Page"
                          className="ant-pagination-prev ant-pagination-disabled"
                          aria-disabled="true"
                        >
                          <button
                            className="ant-pagination-item-link"
                            type="button"
                            tabIndex={-1}
                            disabled=""
                          >
                            <span
                              role="img"
                              aria-label="left"
                              className="anticon anticon-left"
                            >
                              <svg
                                viewBox="64 64 896 896"
                                focusable="false"
                                data-icon="left"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z" />
                              </svg>
                            </span>
                          </button>
                        </li>
                        <li
                          title={1}
                          className="ant-pagination-item ant-pagination-item-1 ant-pagination-item-active"
                          tabIndex={0}
                        >
                          <a rel="nofollow">1</a>
                        </li>
                        <li
                          title="Next Page"
                          className="ant-pagination-next ant-pagination-disabled"
                          aria-disabled="true"
                        >
                          <button
                            className="ant-pagination-item-link"
                            type="button"
                            tabIndex={-1}
                            disabled=""
                          >
                            <span
                              role="img"
                              aria-label="right"
                              className="anticon anticon-right"
                            >
                              <svg
                                viewBox="64 64 896 896"
                                focusable="false"
                                data-icon="right"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z" />
                              </svg>
                            </span>
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div className="notification-container notification-container-empty">
                      <div />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
  return (
    <div _ngcontent-ng-c3705319800="" className="main_content">
      <router-outlet _ngcontent-ng-c3705319800="" />
      <app-all-updte-limit
        _nghost-ng-c2116192893=""
        className="ng-star-inserted"
      >
        <div _ngcontent-ng-c2116192893="" className="page_header">
          <h1 _ngcontent-ng-c2116192893="" className="page_heading">
            {userType} Limit
          </h1>
          <div _ngcontent-ng-c2116192893="" className="breadcrumb_block">
            <nav _ngcontent-ng-c2116192893="" aria-label="breadcrumb">
              <ol _ngcontent-ng-c2116192893="" className="breadcrumb">
                <li _ngcontent-ng-c2116192893="" className="breadcrumb-item">
                  <a _ngcontent-ng-c2116192893="" href="#/dashboard">
                    Home
                  </a>
                </li>
                <li _ngcontent-ng-c2116192893="" className="breadcrumb-item">
                  <a _ngcontent-ng-c2116192893="">{userType} Details</a>
                </li>
                <li
                  _ngcontent-ng-c2116192893=""
                  aria-current="page"
                  className="breadcrumb-item active"
                >
                  {userType} Limit
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div
          _ngcontent-ng-c2116192893=""
          className="Super_master_details_section agentledger_block"
        >
          <div _ngcontent-ng-c2116192893="" className="custom_table">
            <div _ngcontent-ng-c2116192893="" className="table-responsive">
              <form
                _ngcontent-ng-c2116192893=""
                noValidate=""
                className="ng-untouched ng-pristine ng-invalid"
              >
                <table _ngcontent-ng-c2116192893="" className="table">
                  <thead _ngcontent-ng-c2116192893="">
                    <tr _ngcontent-ng-c2116192893="">
                      <th _ngcontent-ng-c2116192893="">SNo.</th>
                      <th _ngcontent-ng-c2116192893="">Client</th>
                      <th _ngcontent-ng-c2116192893="">Current Limit</th>
                      <th _ngcontent-ng-c2116192893="">Add/Minus Limit</th>
                      <th _ngcontent-ng-c2116192893="">
                        {userType == "ADMIN"
                          ? "Super Admin"
                          : userType == "SUB_ADMIN"
                          ? "Admin"
                          : userType == "MASTER"
                          ? "Sub Admin"
                          : userType == "SUPER_MASTER"
                          ? "Master"
                          : userType == "AGENT"
                          ? "Super Master"
                          : userType == "CLIENT"
                          ? "Agent"
                          : ""}{" "}
                        Limit
                      </th>
                      <th _ngcontent-ng-c2116192893="">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody _ngcontent-ng-c2116192893="">
                    {clientData?.childrenUserResponseDTOS?.map(
                      (client, index) => (
                        <tr
                          _ngcontent-ng-c2116192893=""
                          className="ng-star-inserted"
                        >
                          <td _ngcontent-ng-c2116192893="">{index}</td>
                          <td _ngcontent-ng-c2116192893="">
                            <input
                              _ngcontent-ng-c2116192893=""
                              type="text"
                              readOnly=""
                              disabled
                              id="name"
                              name="name"
                              value={client.username + " - " + client.name}
                              className="form-control"
                            />
                          </td>
                          <td _ngcontent-ng-c2116192893="">
                            <input
                              _ngcontent-ng-c2116192893=""
                              type="number"
                              readOnly=""
                              id="name"
                              disabled
                              value={parseFloat(
                                client?.balance + client?.liability
                              ).toFixed(2)}
                              name="name"
                              className="form-control"
                            />
                          </td>
                          <td _ngcontent-ng-c2116192893="">
                            <input
                              _ngcontent-ng-c2116192893=""
                              type="number"
                              id="name"
                              formcontrolname="addChips"
                              name="name"
                              onChange={(e) => {
                                setUpdateCommission({
                                  ...updateCommission,
                                  id: client?.uid,
                                  currentLimit: e.target.value,
                                });
                              }}
                              className="form-control ng-untouched ng-pristine ng-invalid"
                            />
                          </td>
                          <td _ngcontent-ng-c2116192893="">
                            <input
                              _ngcontent-ng-c2116192893=""
                              readOnly=""
                              type="number"
                              disabled
                              id="name"
                              name="name"
                              value={client?.parentLimit}
                              className="form-control"
                            />
                          </td>
                          <td
                            _ngcontent-ng-c2116192893=""
                            style={{ display: "flex" }}
                          >
                            <button
                              _ngcontent-ng-c2116192893=""
                              type="button"
                              onClick={() => handleUpdateCommission("Plus")}
                              className="btn bg_plus"
                            >
                              Add
                            </button>
                            <button
                              _ngcontent-ng-c2116192893=""
                              type="button"
                              onClick={() => handleUpdateCommission("Minus")}
                              className="btn red_plus ms-2"
                            >
                              Minus
                            </button>
                          </td>
                        </tr>
                      )
                    )}

                    {/**/}
                  </tbody>
                </table>
                <div
                  _ngcontent-ng-c2116192893=""
                  className="pagination-cls ng-star-inserted"
                >
                  <pagination-controls
                    _ngcontent-ng-c2116192893=""
                    previouslabel="Previous"
                    nextlabel="Next"
                    screenreaderpaginationlabel="Pagination"
                    screenreaderpagelabel="page"
                    screenreadercurrentlabel="You're on page"
                  >
                    <pagination-template>
                      <nav role="navigation" aria-label="Pagination">
                        <ul className="ngx-pagination ng-star-inserted">
                          <li
                            className={`pagination-previous ${
                              currentPage === 0 ? "disabled" : ""
                            } ng-star-inserted`}
                          >
                            {/**/}
                            <span
                              onClick={() => {
                                setCurrentPage(currentPage - 1);
                              }}
                              aria-disabled={
                                currentPage === 0 ? "true" : "false"
                              }
                              className="ng-star-inserted"
                            >
                              {" "}
                              Previous{" "}
                              <span className="show-for-sr">page</span>
                            </span>
                            {/**/}
                          </li>
                          {/**/}
                          <li className="small-screen">
                            {" "}
                            {1 + currentPage * size} / {totalElements}{" "}
                          </li>
                          <li className="current ng-star-inserted">
                            {/**/}
                            <span
                              aria-live="polite"
                              className="ng-star-inserted"
                            >
                              <span className="show-for-sr">
                                You're on page{" "}
                              </span>
                              <span>{currentPage + 1}</span>
                            </span>
                            {/**/}
                            {/**/}
                          </li>
                          {Array.from({ length: totalPages }, (_, i) => (
                            <li className={`ng-star-inserted `}>
                              <a
                                onClick={() => setCurrentPage(i)}
                                tabIndex={0}
                                className={`ng-star-inserted ${
                                  currentPage === i ? "current" : ""
                                }`}
                              >
                                <span className="show-for-sr">page </span>
                                <span>{i + 1}</span>
                              </a>
                              {/**/}
                              {/**/}
                            </li>
                          ))}

                          <li className="pagination-next ng-star-inserted">
                            <a
                              tabIndex={0}
                              onClick={() => setCurrentPage(currentPage + 1)}
                              className="ng-star-inserted"
                            >
                              {" "}
                              Next <span className="show-for-sr">page</span>
                            </a>
                            {/**/}
                            {/**/}
                          </li>
                          {/**/}
                        </ul>
                        {/**/}
                      </nav>
                    </pagination-template>
                  </pagination-controls>
                </div>
                {/**/}
              </form>
            </div>
          </div>
        </div>
      </app-all-updte-limit>
      {/**/}
    </div>
  );
}
