import React, { useEffect, useState } from "react";
import { bulkDeleteGroupAPI, getAllMatchesAPI } from "../service/AuthService";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import { useMutation, useQuery } from "react-query";
import { getClientBetsCricketAPI, handleBetAPI } from "../service/cricket";
import toast from "react-hot-toast";
import { Flex, Input, Select, Switch } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";

export default function MatchCurrentBets() {
  const location = useLocation();
  const [selectedIds, setSelectedIds] = useState([]);
  const [isFancy, setIsFancy] = useState(false);
  const [betStatus, setBetStatus] = useState("MATCHED");
  const [search, setSearch] = useState("");
  const [selectedSession, setSelectedSession] = useState("");
  const [bulkDelete, setBulkDelete] = useState([]);
  const [betDeleteOpen, setBetDeleteOpen] = useState(false);
  const [password, setPassword] = useState("");
  const id = useParams()?.id;
  const currentBets = useQuery(
    "currentBets",
    () => getClientBetsCricketAPI(id, isFancy, betStatus),
    {
      refetchInterval: 2000,
    }
  );
  const totalLength =
    currentBets?.data?.response?.bets?.[0]?.upperLineExposure?.length;
  const uniqueRunnerNames = currentBets?.data?.response?.bets?.reduce(
    (acc, item) => {
      if (acc?.findIndex((_) => _.selectionName === item?.runnerName) === -1) {
        acc.push({ selectionName: item?.runnerName });
      }
      return acc;
    },
    []
  );
  const handleBet = async (data, action = "DELETED", id_ = false) => {
    const { response, code } = await handleBetAPI(id_ ? id_ : data.id, action);
    if (code === 200) {
      currentBets.refetch();
      toast.success("Bet Deleted Successfully");
    }
  };

  const bulkGroupDelete = async () => {
    const { response, code } = await bulkDeleteGroupAPI(
      bulkDelete.join(),
      password
    );
    if (code === 200) {
      currentBets.refetch();
      setBulkDelete([]);
      setBetDeleteOpen(false);
      toast.success("Bets Deleted Successfully");
    } else {
      toast.error(response);
    }
  };

  useEffect(() => {}, [bulkDelete]);
  return (
    <>
      <Modal show={betDeleteOpen} onHide={() => setBetDeleteOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Are sure want to delete bets</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>Password</label>
          <Input
            type="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              background: "red",
              color: "white",
            }}
            onClick={bulkGroupDelete}
          >
            Delete
          </Button>
          <Button onClick={() => setBetDeleteOpen(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
      <div
        style={{
          paddingTop: "100px",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
        className="content-wrapper"
      >
        <section className="content-header">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h3>
                Bets For {location?.state?.item?.eventName} / Live Report
              </h3>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">
                  <a href="/game/inPlay">Dashboard</a>
                </li>
              </ol>
            </div>
          </div>

          <div>
            <div className="row">
              <div className="col-lg-12">
                <div className="ibox float-e-margins">
                  <div className="ibox-title">
                    <h5>All Bets</h5>
                    <div className="ibox-tools row" />
                  </div>
                  <Flex
                    flexDirection={{
                      base: "column",
                      md: "row",
                    }}
                    margin={5}
                  >
                    <Input
                      w={{ base: "100%", md: "30%" }}
                      type="text"
                      bg="white"
                      placeholder="Search (Username,Eventname)"
                      size="lg"
                      className="form-control input-lg"
                      onChange={(e) =>
                        e.target.value?.length > 3 &&
                        setSearch(e.target.value)
                      }
                    />
                    <Flex mx={5} alignItems="center">
                      <label htmlFor="fancy" mr={2}>
                        Fancy only
                      </label>
                      <Switch
                        mx={2}
                        size="lg"
                        id="fancy"
                        isChecked={isFancy}
                        onChange={() => setIsFancy(!isFancy)}
                      />
                      {isFancy && (
                        <Select
                          w={{ base: "100%", md: "60%" }}
                          placeholder="Select Session"
                          size="lg"
                          bg="white"
                          marginX={5}
                          className="form-control "
                          onChange={(e) => {
                            currentBets.refetch();
                            setSelectedSession(e.target.value);
                          }}
                        >
                          <option
                            onClick={() => {
                              currentBets.refetch();
                              setSelectedSession("");
                            }}
                            value={""}
                          >
                            All
                          </option>
                          {uniqueRunnerNames?.map((item, index) => (
                            <option value={item.selectionName}>
                              {item.selectionName}
                            </option>
                          ))}
                        </Select>
                      )}
                    </Flex>
                  </Flex>
                  <Flex
                    justifyContent={{
                      md: "flex-start",
                      base: "space-between",
                    }}
                  >
                    <button
                      style={{
                        marginRight: "10px",
                      }}
                      onClick={() => {
                        setBetStatus("MATCHED");
                        currentBets.refetch();
                      }}
                      className={`btn ${
                        betStatus === "MATCHED"
                          ? "btn-primary"
                          : "btn-default"
                      }`}
                    >
                      <i className="fa fa-book" />
                      Matched Bets
                    </button>

                    <button
                      onClick={() => {
                        setBetStatus("DELETED");
                        currentBets.refetch();
                      }}
                      className={`btn ${
                        betStatus === "DELETED"
                          ? "btn-primary"
                          : "btn-default"
                      }`}
                    >
                      <i className="fa fa-trash" /> Deleted Bets
                    </button>
                    {betStatus != "DELETED" && (
                      <button
                        style={{
                          marginLeft: "10px",
                        }}
                        onClick={() => {
                          if (bulkDelete.length === 0) {
                            toast.error("Please select bets to delete");
                            return;
                          }
                          setBetDeleteOpen(true);
                        }}
                        className="btn btn-danger"
                      >
                        <i
                          className="
                    fa fa-trash"
                          style={{ marginRight: "5px" }}
                        />
                        Bulk Delete
                      </button>
                    )}
                  </Flex>
                  <div class="ibox-content table-responsive ">
                    {isFancy ? (
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            {betStatus != "DELETED" && (
                              <th>
                                <input
                                  onClick={(e) => {
                                    if (e.target.checked) {
                                      setBulkDelete(
                                        currentBets?.data?.response?.bets?.map(
                                          (item) => item.id
                                        )
                                      );
                                    } else {
                                      setBulkDelete([]);
                                    }
                                  }}
                                  value={bulkDelete.length === totalLength}
                                  className="bulk-delete"
                                  type="checkbox"
                                  name="betId"
                                />
                              </th>
                            )}

                            <th>DATE</th>
                            <th>User</th>
                            <th>Session Title</th>
                            <th>RATE</th>
                            <th>RUNS</th>
                            <th>AMOUNT</th>
                            <th>MODE</th>

                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentBets?.data?.response?.bets
                            ?.filter((item) =>
                              item.runnerName?.includes(selectedSession)
                            )
                            ?.map((item) => (
                              <tr>
                                <td>
                                  <input
                                    onClick={
                                      betStatus != "DELETED"
                                        ? (e) => {
                                            if (e.target.checked) {
                                              setBulkDelete([
                                                ...bulkDelete,
                                                item.id,
                                              ]);
                                            } else {
                                              setBulkDelete(
                                                bulkDelete.filter(
                                                  (id) => id !== item.id
                                                )
                                              );
                                            }
                                          }
                                        : () => {}
                                    }
                                    type="checkbox"
                                    checked={bulkDelete.find(
                                      (_) => _ === item.id
                                    )}
                                    name="betId"
                                  />
                                </td>

                                <td>
                                  <i className="fa fa-clock-o" />
                                  &nbsp;
                                  {moment(item?.placeDate + "Z").format(
                                    "YYYY-MM-DD HH:mm:ss"
                                  )}
                                </td>
                                <td>{item?.userName}</td>
                                <td>{item?.runnerName}</td>
                                <td>{item?.rate}</td>
                                <td>{item?.run}</td>
                                <td>{item?.amount}</td>
                                <td>{item?.back ? "YES" : "NO"}</td>

                                <td>
                                  <button
                                    onClick={() => handleBet(item)}
                                    className="btn btn-danger"
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    ) : (
                      <table class="table table-bordered table-striped">
                        <thead>
                            <tr>
                              
                            {betStatus != "DELETED" && (
                              <th>
                                <input
                                  onClick={(e) => {
                                    if (e.target.checked) {
                                      setBulkDelete(
                                        currentBets?.data?.response?.bets?.map(
                                          (item) => item.id
                                        )
                                      );
                                    } else {
                                      setBulkDelete([]);
                                    }
                                  }}
                                  value={bulkDelete.length === totalLength}
                                  className="bulk-delete"
                                  type="checkbox"
                                  name="betId"
                                />
                              </th>
                            )}
                            <th>DATE</th>
                            <th>Market Title</th>
                            <th>RATE</th>
                            <th>AMOUNT</th>
                            <th>MODE</th>
                            <th>Runner Name</th>
                            <th>User</th>

                            <th>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {currentBets?.data?.response?.bets?.map((item) => (
                            <tr>
                              <td>
                                {item?.id}
                              </td>
                              <td>
                                <i class="fa fa-clock-o" />
                                &nbsp;
                                {moment(item?.placeDate + "Z").format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )}
                              </td>
                              <td>{item?.marketName}</td>
                              <td>{item?.rate}</td>
                              <td>{item?.amount}</td>
                              <td>
                                {!isFancy
                                  ? item?.back
                                    ? "LAGAI"
                                    : "KHAI"
                                  : item?.back
                                  ? "YES"
                                  : "NO"}
                              </td>
                              <td>{item?.runnerName}</td>
                              <td>{item?.userName}</td>

                              <td>
                                <button
                                  onClick={() => handleBet(item)}
                                  className="btn btn-danger"
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
