import { JSEncrypt } from "jsencrypt";
import moment from "moment/moment";
import { casinoPL, cricketBookMakerPL, cricketFancyPL } from "./profitLoss";
import store from "../redux/store";
import sha256 from "crypto-js/sha256";
export const PROD_IP = process.env.REACT_APP_API_URL; 
export const DEV_IP = process.env.REACT_APP_API_URL;
export const APP_NAME = process.env.REACT_APP_NAME;

var BASE_URL_;
var CRICKET_SERVICE_URL_;
var SOCCER_AND_TENNIS_SERVICE_URL_;
var CASINO_SERVICE_URL_;

if (PROD_IP.startsWith("api")) {
  BASE_URL_ = `https://${PROD_IP}/aam/v1`;
  CRICKET_SERVICE_URL_ = `https://${PROD_IP}/cm/v1`;
  SOCCER_AND_TENNIS_SERVICE_URL_ = `https://${PROD_IP}/tas/v1`;
  CASINO_SERVICE_URL_ = `https://${process.env.REACT_APP_API_URL}/casino/v1`;
} else {
  BASE_URL_ = `http://${process.env.REACT_APP_API_URL}:8000/api`;
  CRICKET_SERVICE_URL_ = `http://${process.env.REACT_APP_API_URL}:8001/api`;
  SOCCER_AND_TENNIS_SERVICE_URL_ = `http://${
    process.env.REACT_APP_API_URL
  }:8002/api`;
  CASINO_SERVICE_URL_ = `http://${
    process.env.REACT_APP_API_URL
  }:8003/api/casino`;
}
export const BASE_URL = BASE_URL_;
export const CRICKET_SERVICE_URL = CRICKET_SERVICE_URL_;
export const SOCCER_AND_TENNIS_SERVICE_URL = SOCCER_AND_TENNIS_SERVICE_URL_;
export const CASINO_SERVICE_URL = CASINO_SERVICE_URL_;
export const PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
MIIBITANBgkqhkiG9w0BAQEFAAOCAQ4AMIIBCQKCAQB0ZacT13Ok+3pX0lmuJ9dJ
F6atV7Vwv5gmgpvXiBVYKKM4sfGb2qg395uVcampRgLrM63KxElPkWaa90j10ol6
fnHneE3sGuNIrvUmSvtqgeSD8nS4GXdkDecD+HSB7OCOcr+oxsGZzXJdzN/5QyUd
DGpRTlm8T+yB6wa9sJPPgZJ/C650JF57WF+G3qSngqJZEtGrKLlZYL24AqVEg85d
gLsrawWpZUEGeYqhYsdSeoGqkAR6E8Ybw+YWEYCFyMLZi2Fe5MVwMARWQ5kzpKTx
0ur7DPsghrAem08DIAekAN97F1DS48Vs88T29C2716ylE27ICyiMLDw9DLXekhAD
AgMBAAE=
-----END PUBLIC KEY-----`;
export const buttonColors = "yellow.400";
export const bgColor = "#383838";
export const secondaryColor = "#3C444B";
export const applyFancyFor = [
  "Over By Over",
  "Normal",
  "Ball By Ball",
  "meter",
  "fancy1",
];
export const sortByStringNumberInEnd = (a, b) => {
  const aNumber = parseInt(a);
  const bNumber = parseInt(b);
  if (aNumber && bNumber) {
    return aNumber - bNumber;
  } else if (aNumber) {
    return -1;
  } else if (bNumber) {
    return 1;
  } else {
    return a.localeCompare(b);
  }
};
var ENCRYPT = new JSEncrypt();
ENCRYPT.setPublicKey(PUBLIC_KEY);
export const Encrypt = (data) => ENCRYPT.encrypt(data);
export const getToken = () => {
  return store.getState().account.userData.token;
};
export const getCurrentDateTimeLocal = (
  prev = false,
  date_ = false,
  time = false
) => {
  let date = new Date(); // create a new Date object with the current date and time
  let year = date.getFullYear(); // get the four-digit year (e.g. 2023)
  let month = ("0" + (date.getMonth() + 1)).slice(-2); // get the month as a zero-padded two-digit string (e.g. "03")
  let day = ("0" + date.getDate()).slice(-2); // get the day of the month as a zero-padded two-digit string (e.g. "04")
  let hour = ("0" + date.getHours()).slice(-2); // get the hour as a zero-padded two-digit string (e.g. "09")
  let minute = ("0" + date.getMinutes()).slice(-2); // get the minute as a zero-padded two-digit string (e.g. "05")
  if (prev) {
    if (date_) {
      day = ("0" + (date.getDate() - date_)).slice(-2);
    } else {
      day = ("0" + (date.getDate() - 1)).slice(-2);
    }
  }
  let formattedDate = `${year}-${month}-${day} ${hour}:${minute}`; // concatenate the year, month, and day with hyphens as the separator
  return formattedDate;
};

export const convertEpochToDate = (epoch) => {
  return moment(epoch).format("DD/MM/YYYY hh:mm A");
};
export function searchSubstringInArrayObjects(array, keys, searchString) {
  return array.filter((obj) => {
    return keys.some((key) => {
      if (!obj[key]) return false;
      return String(obj[key])
        .toLowerCase()
        .includes(searchString.toLowerCase());
    });
  });
}
export const getProfit = (selectedAmount, game, selectedEventForBet) => {
  let profit;
  let loss;
  let selectedAmount_ = selectedEventForBet.amount;
  if (game == "cricket" && selectedEventForBet.isBookmaker) {
    profit = cricketBookMakerPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).profit;
    loss = cricketBookMakerPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).loss;
  } else if (game == "casino" || game == "tennis" || game == "soccer") {
    profit = casinoPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).profit;
    loss = casinoPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).loss;
  } else if (game == "cricket" && selectedEventForBet.isFancy) {
    profit = cricketFancyPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).profit;
    loss = cricketFancyPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).loss;
  } else {
    profit = casinoPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).profit;
    loss = casinoPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).loss;
  }
  return {
    profit: parseFloat(profit).toFixed(2),
    loss: parseFloat(loss).toFixed(2),
  };
};

export const PERMISSION_MAP = {
  SUPER_ADMIN: [
    "ADMIN",
    "SUB_ADMIN",
    "MASTER",
    "SUPER_AGENT",
    "AGENT",
    "CLIENT",
  ],
  ADMIN: ["SUB_ADMIN", "MASTER", "SUPER_AGENT", "AGENT", "CLIENT"],
  SUB_ADMIN: ["MASTER", "SUPER_AGENT", "AGENT", "CLIENT"],
  MASTER: ["SUPER_AGENT", "AGENT", "CLIENT"],
  SUPER_AGENT: ["AGENT", "CLIENT"],
  AGENT: ["CLIENT"],
};
export const generateFingerprint = async () => {
  return await sha256(
    String(new Date().getTime()) + String(Math.floor(Math.random() * 1000000))
  );
};

export const domainMap = {
         localhost: "a89e8fce7944fdcfb1a9ddf5af381b27",
         superadmin: "a89e8fce7944fdcfb1a9ddf5af381b27",
         master: "6e4959d376a3ede4f9cc4e40872e0414",
         admin: "e1e5c80343ae5a0777eeb6488269cade",
         sub: "8fb6ec5f08b1ce5054410d6b1efe6b49",
         super: "f0d4bee5bee22e2b205713a0967ae932",
         agent: "fec0d2545b1f71c3fc31cbd9dba1b306",
       };

export const getXOrigin = (user = false) => {
  let domain = window.location.hostname.split(".")[0];
  return user ? domainMap[user] : domainMap[domain];
};

export const CASINO_GAMES = {
  TEEN_PATTI: "TEEN_20",
  DRAGON_TIGER: "DRAGON_TIGER_20",
  LUCKY_7: "LUCKY7",
  CARD_32: "CARD_32",
  ANDAR_BAHAR: "ABJ",
};
export const CASINOS = ["DRAGON_TIGER_20", "TEEN_20", "ABJ", "LUCKY7"];

export const CASINO_VIDEO_URL = "https://video.proexch.in/route/?id=";

export const PLATFORM = process.env.REACT_APP_NAME;